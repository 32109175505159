<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="{ open: open, placeholder: !hasValue,
        'is-success': type === 'is-success', 'is-danger': type === 'is-danger' }"
      @click="open = options.length > 0 && !open && !disabled">
      <span>
        {{ hasValue ? selected : placeholder }}
      </span>
      <span>
        <template v-if="canDelete && !disabled && !!this.selected">
          <span class="clear" >
              <span class="clearButton">
                  <img @click="clearSelection()" src="@/assets/x-circle@1x.svg" alt=""/>
              </span>
              <span class="divider"></span>
          </span>
        </template>
        <i v-bind:class="{'fa-chevron-up': open, 'fa-chevron-down': !open}" class="fas has-text-grey"></i>
      </span>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          hasValue = true;
          open = false;
          $emit('input', option);">
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Select'
    },
    options: {
      type: Array,
      required: true
    },
    default: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: false
    },
    canDelete: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    options: function (newVal, oldVal) {
      if (this.hasValue && !newVal.includes(this.selected)) {
        if (this.default && this.newVal.includes(this.default)) {
          this.selected = this.default
        } else {
          this.hasValue = false
          this.selected = null
        }
      }
    },
    disabled: function (value) {
      if (value) {
        this.hasValue = false
        this.selected = null
      }
    },
    value: function (newVal) {
      this.selected = newVal
      this.hasValue = !!newVal
    },
    default: function (newVal) {
      if (!this.selected) {
        this.selected = this.default
      }
    }
  },
  data () {
    const hasValue = this.default || this.value

    return {
      selected: this.default ? this.default : this.value ?? null,
      hasValue: hasValue,
      open: false
    }
  },
  methods: {
    clearSelection () {
      this.hasValue = false
      this.selected = null
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  min-height: 47px;
  line-height: 47px;
}
.custom-select .selected {
  background-color: white;
  border-radius: 4px;
  border: 2px solid lightgrey;
  color: black;
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1em;
}
.custom-select .selected.open {
  border: 1px solid rgb(38, 132, 255);
  box-shadow: 0px 0px 0px 2px rgba(75, 178, 218, 0.192)
}
.custom-select .selected.is-success {
  border: 2px solid hsl(141, 71%, 48%);
}
.custom-select .selected.is-success.open {
  box-shadow: 0px 0px 0px 2px  hsla(141, 71%, 48%, 0.226);
}
.custom-select .selected.is-danger {
  border: 1px solid #d62020;
}
.custom-select .selected.is-danger.open {
  box-shadow: 0px 0px 0px 2px hsla(348, 100%, 61%, 0.185);
}
.custom-select .items {
  transform: translateY(2px);
  box-shadow: 0px 0px 12px -8px grey;
  color: black;
  border-radius: 6px 6px 6px 6px;
  overflow-x: hidden;
  overflow-y: overlay;
  border: 2px solid lightgrey;
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  z-index: 100;
  max-height: 300px;
}
.custom-select .items div {
  color: black;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1em;
}
.custom-select .items div:hover {
  background-color: rgb(222, 235, 255);
}
.selectHide {
  display: none;
}
.placeholder {
  color: lightgrey !important;
}
.select-icon {
  margin-left: auto !important;
  margin-right: 0 !important;
}
* {
  scrollbar-width: thin;
  scrollbar-color: lightgrey transparent;
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 12px;
}

.custom-select .open .fa-chevron-down {
  transform: rotate(180deg);
}

.custom-select span.clear {
    position: absolute;
    top: 0;
    right: 40px !important;
    height: 51px;
    pointer-events: none;
    display: flex;
    align-items: center;
    color: #7a7a7a !important;
    font-size: 18px;
    z-index: 1;
  }

  .custom-select span.clearButton {
    margin-top: 7px;
    cursor: pointer;
    pointer-events: all;
  }
  .custom-select span.divider {
    width: 1px;
    height: 24px;
    border-right: 1px solid #bbbdbf;
    margin-top: 24px;
    margin-left: 5px;
    margin-right: 5px;
  }
</style>
