<template>
    <b-message
        :class="[
            'row-message',
            isPlanSetup ? 'plan-setup message-wrapper' : '',
            messageType === 'is-danger' ? 'error-message' : ''
        ]"
        :type="messageType"
    >
        <div class="message-container">
            <div class="message-content">

                <!-- Message Icons -->
                <i v-if="messageType === 'is-danger'" class="fas fa-exclamation-circle is-error-icon"></i>
                <i v-if="messageType === 'is-warning'" class="fas fa-exclamation-triangle is-warning-icon"></i>

                <div class="message-text">
                    <span class="message-title">
                        {{ titleMessage }}
                    </span>
                    <span class="message-text-format">
                        {{ textMessage }}
                    </span>
                </div>
            </div>
            <b-icon
                v-if="hasCloseMessage"
                pack="fas"
                icon="times"
                class="close-button"
                @click.native="() => onCloseHandler()">
            </b-icon>
        </div>
    </b-message>
</template>

<script>
export default {
  props: {
    hasCloseMessage: Boolean,
    titleMessage: String,
    textMessage: String,
    isPlanSetup: Boolean,
    messageType: {
      type: String,
      validator: value => {
        return [
          'is-danger',
          'is-warning'
        ].includes(value.toLowerCase())
      }
    }
  },
  methods: {
    onCloseHandler () {
      this.$emit('onClose')
    }
  }
}
</script>

<style>
</style>
