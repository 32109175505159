<template>
  <div class="container step-container">
  <b-loading :is-full-page="true" v-model="loading"></b-loading>
    <div class="content-wrapper">
      <div class="columns">
        <div class="column">
          <b-field>
            <b-upload drag-drop animated expanded accept=".pdf" @input="uploadFiles" v-model="currentFile">
              <section class="section upload-section">
                <div class="content has-text-centered has-text-grey open-sans">
                  <i class="fas fa-cloud-upload-alt fa-5x"></i>
                  <p class="upload-text">Drag and drop a file to upload or <span class="has-text-primary">click to browse</span></p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="has-text-left" v-if="currentTabFailedUploads.length > 0">
            <span class="open-sans is-size-5 has-text-weight-bold">
              The following files failed to upload:
            </span>
            <span class="open-sans is-size-5 has-text-danger" v-for="failed in currentTabFailedUploads" :key="failed.fileName">
              {{ failed.fileName }}
            </span>
          </div>
        </div>
        <div class="column">
          <b-tabs v-model="currentDocType" type="is-boxed">
            <b-tab-item value="spd">
              <template #header>
                <span class="custom-tab-header is-size-5">SPD</span>
              </template>
              <div class="has-text-left">
                <p class="has-text-grey is-size-6">Max of 50MB PDF file can be uploaded</p>
                <br>
                <div v-if="addedFiles.spd" :key="addedFiles.spd.name">
                  <a class="has-text-grey is-size-6 res-text" @click="viewResource(addedFiles.spd)">{{ addedFiles.spd.name }}</a>
                  <b-icon
                    pack="far"
                    icon="times-circle"
                    size="is-small"
                    type="is-danger"
                    @click.native="removeUpload()">
                  </b-icon>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item value="sbc">
              <template #header>
                <span class="custom-tab-header is-size-5">SBC</span>
              </template>
              <div class="has-text-left">
                <p class="has-text-grey is-size-6">Max of 50MB PDF file can be uploaded</p>
                <br>
                <div v-if="addedFiles.sbc" :key="addedFiles.sbc.name">
                  <a class="has-text-grey is-size-6 res-text" @click="viewResource(addedFiles.sbc)">{{ addedFiles.sbc.name }}</a>
                  <b-icon
                    pack="far"
                    icon="times-circle"
                    size="is-small"
                    type="is-danger"
                    @click.native="removeUpload()">
                  </b-icon>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadApi from '@/api/uploadApi.js'
import store from '@/store/store'

export default {
  name: 'setup',
  data () {
    const defaultAddedFiles = store.getters['plans/setup/getSetupData'] ?? {
      idCard: null,
      spd: null,
      sbc: null
    }

    return {
      loading: false,
      currentDocType: 'spd',
      currentFile: null,
      addedFiles: defaultAddedFiles,
      acceptableFileTypes: {
        idCard: ['pdf', 'jpg', 'jpeg', 'png', 'svg'],
        spd: ['pdf'],
        sbc: ['pdf']
      },
      failedUploads: [],
      fileSizeLimit: 50000000 // 50 MB
    }
  },
  computed: {
    currentTabFailedUploads () {
      return this.failedUploads.filter((upload) => upload.docType === this.currentDocType)
    }
  },
  methods: {
    uploadFiles (fileInput) {
      const file = Array.isArray(fileInput)
        ? (fileInput.length > 0 ? fileInput[0] : null)
        : fileInput
      if (file === null) {
        return
      }

      this.currentFile = file
      this.currentTabFailedUploads.length > 0 && (this.failedUploads = this.failedUploads.filter(
        (upload) => !this.currentTabFailedUploads.includes(upload)
      ))

      const fnMatch = file.name.match(/^(.*)\.(.*)$/)
      const fn = fnMatch ? fnMatch['1'] : file.name
      const ft = file.type.split('/').splice(1, 1)

      if (file.size > this.fileSizeLimit ||
        !this.acceptableFileTypes[this.currentDocType].includes(ft[0]) ||
        this.addedFiles[this.currentDocType]?.name === file.name) {
        this.setFailedUpload(file.name)
      } else {
        this.loading = true
        uploadApi.getPostUrl(fn, ft, this.currentDocType).then((signedUrlRes) => {
          uploadApi.postFile(signedUrlRes.data.signedUrl, file).then((uploadRes) => {
            this.addedFiles[this.currentDocType] = {
              name: file.name,
              objectUrl: signedUrlRes.data.publicObjectUrl
            }
            this.loading = false
            this.currentFile = null
          }).catch((err) => {
            this.setFailedUpload(file.name)
            console.error(`Error while uploading file "${file.name}"`)
            console.error(err)
            this.loading = false
            this.currentFile = null
          })
        }).catch((err) => {
          this.setFailedUpload(file.name)
          console.error(`Error retrieving signed url for file "${file.name}"`)
          console.error(err)
          this.loading = false
        })
      }
    },
    setFailedUpload (fileName) {
      if (!this.failedUploads.includes(fileName)) {
        this.failedUploads.push({
          fileName: fileName,
          docType: this.currentDocType
        })
      }
    },
    removeUpload () {
      this.addedFiles[this.currentDocType] = null
      this.$forceUpdate()
    },
    viewResource (file) {
      window.open(file.objectUrl, '_blank')
    },
    saveSubmit () {
      store.dispatch('plans/setup/setSetupData', this.addedFiles)
      return true
    }
  }
}
</script>

<style scoped>
.upload-section {
  padding-top: 8vw;
  padding-bottom: 8vw;
}
.upload-text {
  padding-top: 20px;
}
.res-text {
  margin-right: 4px;
}
li.is-active .custom-tab-header {
  color: #00a69e;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}
li .custom-tab-header {
  color: hsl(0%, 0%, 48%);
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}
</style>
