
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'toggle',
  inheritAttrs: false,
  methods: {
    onChange (e: InputEvent): void {
      this.$emit('change', e)
    }
  }
})
