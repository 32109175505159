<template>
    <div class="container">
      <div class="step-title">
        <p>Member cost share inputs</p>
      </div>

      <!-- For editing Member Cost Table headers -->
      <div class="user-input-wrapper">
          <b-field
            label="Tier 1"
            :label-position="labelPosition"
            :type="formDisplay.memberCostTier1.type"
            :message="formDisplay.memberCostTier1.message"
          >
            <b-input v-model.trim="memberCostTier1" @input="checkMemberCostTier1"></b-input>
          </b-field>
          <b-field
            label="Tier 2"
            :label-position="labelPosition"
            :type="formDisplay.memberCostTier2.type"
            :message="formDisplay.memberCostTier2.message"
          >
            <b-input v-model.trim="memberCostTier2" @input="checkFieldHasNoSlash('memberCostTier2')"></b-input>
          </b-field>
      </div>

      <div class="content-wrapper">
        <div class="cost-inputs-table">
        <b-table
          :data.sync="memberCostData"
          :bordered="true"
          :sticky-header="true"
          :striped="true"
          :checkable="true"
          :checked-rows.sync="checkedMemberCostRows"
          @check="(checkedList, row) => onRowCheck(checkedList, row)"
          height="770"
          class="member-cost-table"
        >
          <b-table-column
            label="Category"
            v-slot="props"
            header-class="has-background-light no-border taller-cell column-header"
            cell-class="left-align category-cell"
          >
            <b-field
              class="step-field"
              :type="props.row.isError ? 'is-danger' : ''"
              :message="props.row.isError ? formErrors.category : ''"
            >
              <b-input v-if="props.row.last == true" v-model.trim="props.row.category" @blur="addMemberCostsRow"></b-input>
              <b-input v-if="!props.row.last" v-model.trim="props.row.category" @input="checkRowHasCategory(props.row)"></b-input>
            </b-field>
          </b-table-column>
          <b-table-column
            :label="`Individual Tier 1 ${memberCostTier1 ? `- ${memberCostTier1}` : ''}`"
            v-slot="props"
            header-class="has-background-light no-border taller-cell column-header equal-column"
            cell-class=""
          >
            <b-input v-if="props.row.last == true" v-model.trim="props.row.individualTier1" @blur="addMemberCostsRow"></b-input>
            <b-input v-if="!props.row.last" v-model.trim="props.row.individualTier1" ></b-input>
          </b-table-column>
          <b-table-column
            :label="`Individual Tier 2 ${memberCostTier2 ? `- ${memberCostTier2}` : ''}`"
            v-slot="props"
            header-class="has-background-light no-border taller-cell column-header equal-column"
            cell-class="relative">
            <b-input v-if="props.row.last == true" v-model.trim="props.row.individualTier2" @blur="addMemberCostsRow"></b-input>
            <b-input v-if="!props.row.last" v-model.trim="props.row.individualTier2" ></b-input>
          </b-table-column>
          <b-table-column
            :label="`Family Tier 1 ${memberCostTier1 ? `- ${memberCostTier1}` : ''}`"
            v-slot="props"
            header-class="has-background-light no-border taller-cell column-header equal-column"
            cell-class=""
          >
            <b-input v-if="props.row.last == true" v-model.trim="props.row.familyTier1" @blur="addMemberCostsRow"></b-input>
            <b-input v-if="!props.row.last" v-model.trim="props.row.familyTier1" ></b-input>
          </b-table-column>
          <b-table-column
            :label="`Family Tier 2 ${memberCostTier2 ? `- ${memberCostTier2}` : ''}`"
            v-slot="props"
            header-class="has-background-light no-border taller-cell column-header equal-column"
            cell-class="relative">
            <b-input v-if="props.row.last == true" v-model.trim="props.row.familyTier2" @blur="addMemberCostsRow"></b-input>
            <b-input v-if="!props.row.last" v-model.trim="props.row.familyTier2" ></b-input>
          </b-table-column>
        </b-table>

        <b-button @click.native="addRow" class="mt-3">
          New row
        </b-button>

        </div>
        <div class="rx-table-wrapper">
          <div class="step-title">
            <p>Rx Coverage</p>
            <div class="user-input-wrapper">
              <b-field label="Custom Header 1" :label-position="labelPosition">
                <b-input v-model.trim="userInput1"></b-input>
              </b-field>
              <b-field label="Custom Header 2" :label-position="labelPosition">
                <b-input v-model.trim="userInput2"></b-input>
              </b-field>
            </div>
          </div>
          <b-table
          :data="rxCoverageData"
          :bordered="true"
          :sticky-header="true"
          :striped="true"
          :checkable="true"
          :checked-rows.sync="checkedRxCoverageRows"
          class="rx-table">
            <b-table-column label="Category" v-slot="props"
            header-class="has-background-light no-border taller-cell column-header equal-column"
            cell-class="left-align">
            <span v-if="props.row.static == true">{{ props.row.category }}</span>
              <b-input v-if="props.row.last == true" v-model.trim="props.row.category" @blur="addRxRow"></b-input>
              <b-input v-if="!props.row.last" v-model.trim="props.row.category" ></b-input>
            </b-table-column>
            <b-table-column :label="userInput1" v-slot="props"
            header-class="has-background-light no-border column-header equal-column"
            cell-class="">
              <b-input v-if="props.row.last == true" v-model.trim="props.row.userInput1" @blur="addRxRow"></b-input>
              <b-input v-if="!props.row.last" v-model.trim="props.row.userInput1" ></b-input>
            </b-table-column>
            <b-table-column :label="userInput2" v-slot="props"
            header-class="has-background-light column-header"
            cell-class="">
              <b-input v-if="props.row.last == true" v-model.trim="props.row.userInput2" @blur="addRxRow"></b-input>
              <b-input v-if="!props.row.last" v-model.trim="props.row.userInput2"></b-input>
            </b-table-column>
          </b-table>
        </div>
        <div class="rx-table-wrapper">
          <div class="step-title">
            <p>Rx Specialty</p>
              <div class="user-input-wrapper">
                <b-field label="Rx Specialty Custom Header 1" :label-position="labelPosition">
                  <b-input v-model.trim="rxSpecialtyUserInput1"></b-input>
                </b-field>
                <b-field label="Rx Specialty Custom Header 2" :label-position="labelPosition">
                  <b-input v-model.trim="rxSpecialtyUserInput2"></b-input>
                </b-field>
              </div>
          </div>
          <b-table
          :data="rxCoverageSpecialtyData"
          :bordered="true"
          :sticky-header="true"
          :striped="true"
          :checkable="true"
          :checked-rows.sync="checkedRxCoverageSpecialtyRows"
          class="rx-table">
            <b-table-column label="Category" v-slot="props"
            header-class="has-background-light no-border taller-cell column-header equal-column"
            cell-class="left-align">
            <span v-if="props.row.static == true">{{ props.row.category }}</span>
              <b-input v-if="props.row.last == true" v-model.trim="props.row.category" @blur="addRxSpecialtyRow"></b-input>
              <b-input v-if="!props.row.last" v-model.trim="props.row.category" ></b-input>
            </b-table-column>
            <b-table-column :label="rxSpecialtyUserInput1" v-slot="props"
            header-class="has-background-light no-border column-header equal-column"
            cell-class="">
              <b-input v-if="props.row.last == true" v-model.trim="props.row.userInput1" @blur="addRxSpecialtyRow"></b-input>
              <b-input v-if="!props.row.last" v-model.trim="props.row.userInput1" ></b-input>
            </b-table-column>
            <b-table-column :label="rxSpecialtyUserInput2" v-slot="props"
            header-class="has-background-light column-header"
            cell-class="">
              <b-input v-if="props.row.last == true" v-model.trim="props.row.userInput2" @blur="addRxSpecialtyRow"></b-input>
              <b-input v-if="!props.row.last" v-model.trim="props.row.userInput2"></b-input>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
</template>

<script>
import store from '@/store/store'

export default {
  name: 'member-costs',
  props: [],
  data () {
    this.getMemberCostData()
    this.getRXCoverageData()
    this.getPlanDesignData()
    this.getRxCoverageSpecialtyData()
    this.getCustomMemberCostHeaders()
    this.getCustomRxHeaders()
    this.getCustomRxSpecialtyHeaders()
    return {
      labelPosition: 'inside',
      memberCostData: [],
      rxCoverageData: [],
      rxCoverageSpecialtyData: [],
      planDesignData: null,
      memberCostTier1: '',
      memberCostTier2: '',
      name: '',
      checkedMemberCostRows: [],
      checkedRxCoverageRows: [],
      checkedRxCoverageSpecialtyRows: [],
      interval: null,
      userInput1: 'Header 1',
      userInput2: 'Header 2',
      rxSpecialtyUserInput1: 'Header 1',
      rxSpecialtyUserInput2: 'Header 2',
      formDisplay: {
        category: { type: '', message: '' },
        memberCostTier1: { type: '', message: '' },
        memberCostTier2: { type: '', message: '' }
      },
      formErrors: {
        category: 'Field cannot be empty if the category is checked off.',
        memberCostTier1: 'Please enter a plan tier 1',
        memberCostSlash: 'Field cannot contain forward slashes.',
        tier2NonUniqueValueError: 'Please enter a unique label for each tier'
      }
    }
  },
  created () {
    this.interval = setInterval(() => { this.getPlanDesignData() }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    async getMemberCostData () {
      this.$store.dispatch('plans/memberCosts/getMemberCostData')
        .then((res) => {
          this.memberCostData = res
          this.checkedMemberCostRows = this.memberCostData.filter((row) => row.isChecked)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getRXCoverageData () {
      this.$store.dispatch('plans/memberCosts/getRXCoverageData')
        .then((res) => {
          this.rxCoverageData = res
          this.checkedRxCoverageRows = this.rxCoverageData.filter((row) => row.isChecked)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getRxCoverageSpecialtyData () {
      this.$store.dispatch('plans/memberCosts/getRxCoverageSpecialtyData')
        .then((res) => {
          this.rxCoverageSpecialtyData = res
          this.checkedRxCoverageSpecialtyRows = this.rxCoverageSpecialtyData.filter((row) => row.isChecked)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getPlanDesignData () {
      if (store.getters['plans/planDesign/getPlanDesignData'] != null) {
        this.planDesignData = store.getters['plans/planDesign/getPlanDesignData']

        if (this.memberCostData && this.planDesignData.pcpDesignation.value) {
          var pcpVisitObj = this.planDesignData.pcpDesignation.value === 'required'
            ? this.memberCostData.findIndex((ele) => ele.category === 'PCP Visits')
            : this.memberCostData.findIndex((ele) => ele.category === 'Designated PCP Visits')

          if (pcpVisitObj !== -1) {
            this.memberCostData[pcpVisitObj].category = this.planDesignData.pcpDesignation.value === 'required'
              ? 'Designated PCP Visits'
              : 'PCP Visits'
          }
        }
      }
    },
    async getCustomMemberCostHeaders () {
      this.$store.dispatch('plans/memberCosts/getCustomMemberCostHeaders')
        .then((res) => {
          if (res != null) {
            this.memberCostTier1 = res.tier1
            this.memberCostTier2 = res.tier2
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getCustomRxHeaders () {
      this.$store.dispatch('plans/memberCosts/getCustomRxHeaders')
        .then((res) => {
          if (res != null) {
            this.userInput1 = res.customHeader1
            this.userInput2 = res.customHeader2
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getCustomRxSpecialtyHeaders () {
      this.$store.dispatch('plans/memberCosts/getCustomRxSpecialtyHeaders')
        .then((res) => {
          if (res != null) {
            this.rxSpecialtyUserInput1 = res.customHeader1
            this.rxSpecialtyUserInput2 = res.customHeader2
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    addRow () {
      this.memberCostData.push({
        category: '',
        familyTier1: '',
        familyTier2: '',
        individualTier1: '',
        individualTier2: ''
      })
    },
    saveSubmit () {
      if (
        this.checkMemberCostTier1() &&
        this.checkFieldHasNoSlash('memberCostTier2') &&
        !this.memberCostData.find((data) => data.isError === true)
      ) {
        store.dispatch('plans/memberCosts/setMemberCostData', this.memberCostData)
        store.dispatch('plans/memberCosts/setRxCoverageData', this.rxCoverageData)
        store.dispatch('plans/memberCosts/setRxCoverageSpecialtyData', this.rxCoverageSpecialtyData)
        store.dispatch('plans/memberCosts/setCheckedMemberCostData', this.checkedMemberCostRows)
        store.dispatch('plans/memberCosts/setCheckedRxCoverageData', this.checkedRxCoverageRows)
        store.dispatch('plans/memberCosts/setCheckedRxCoverageSpecialtyData', this.checkedRxCoverageSpecialtyRows)
        store.dispatch('plans/memberCosts/setCustomRxHeaders', { customHeader1: this.userInput1, customHeader2: this.userInput2 })
        store.dispatch('plans/memberCosts/setCustomRxSpecialtyHeaders', { customHeader1: this.rxSpecialtyUserInput1, customHeader2: this.rxSpecialtyUserInput2 })
        store.dispatch('plans/memberCosts/setCustomMemberCostHeaders', { tier1: this.memberCostTier1, tier2: this.memberCostTier2 })

        return true
      } else {
        return false
      }
    },
    addRxRow () {
      const currentLast = this.rxCoverageData[this.rxCoverageData.length - 1]

      if (currentLast.category !== '' || currentLast.userInput1 !== '' || currentLast.userInput2 !== '') {
        delete this.rxCoverageData[this.rxCoverageData.length - 1].last
        this.rxCoverageData.push({ category: '', userInput1: '', userInput2: '', last: true })
      }
    },
    addRxSpecialtyRow () {
      const currentLast = this.rxCoverageSpecialtyData[this.rxCoverageSpecialtyData.length - 1]

      if (currentLast.category !== '' || currentLast.userInput1 !== '' || currentLast.userInput2 !== '') {
        delete this.rxCoverageSpecialtyData[this.rxCoverageSpecialtyData.length - 1].last
        this.rxCoverageSpecialtyData.push({ category: '', userInput1: '', userInput2: '', last: true })
      }
    },
    addMemberCostsRow () {
      const currentLast = this.memberCostData[this.memberCostData.length - 1]

      if (currentLast.category !== '' ||
          currentLast.individualTier1 !== '' ||
          currentLast.individualTier2 !== '' ||
          currentLast.familyTier1 !== '' ||
          currentLast.familyTier2 !== '') {
        delete this.memberCostData[this.memberCostData.length - 1].last
        this.memberCostData.push({ category: '', individualTier1: '', individualTier2: '', familyTier1: '', familyTier2: '', last: true })
      }
    },
    checkMemberCostTier1 () {
      if (!this.checkFieldHasValue('memberCostTier1')) {
        return false
      } else {
        return this.checkFieldHasNoSlash('memberCostTier1')
      }
    },
    checkFieldHasValue (value) {
      if (!this[value] || this[value] === '') {
        this.formDisplay[value].type = 'is-danger'
        this.formDisplay[value].message = this.formErrors.memberCostTier1
        return false
      } else {
        this.formDisplay[value].type = ''
        this.formDisplay[value].message = ''
        return true
      }
    },
    checkFieldHasNoSlash (value) {
      this.formDisplay[value].type = ''
      this.formDisplay[value].message = ''
      if (!this[value]) {
        return true
      }
      if (this[value].indexOf('/') >= 0) {
        this.formDisplay[value].type = 'is-danger'
        this.formDisplay[value].message = this.formErrors.memberCostSlash
        return false
      }
      if (['memberCostTier1', 'memberCostTier2'].includes(value)) {
        return this.checkUniquenessBetweenTiers('memberCostTier2')
      }
      return true
    },

    // Displays error under Tier 2 field when both Tier values are same.
    checkUniquenessBetweenTiers (value) {
      if (this.checkFieldHasValue('memberCostTier1') && this.memberCostTier1 === this[value]) {
        this.formDisplay[value].type = 'is-danger'
        this.formDisplay[value].message = this.formErrors.tier2NonUniqueValueError
        return false
      }
      this.formDisplay[value].type = ''
      this.formDisplay[value].message = ''
      return true
    },

    checkRowHasCategory (row) {
      let isError = false
      const currentCheckedRow = this.checkedMemberCostRows.find((data) => data.id === row.id)

      if (currentCheckedRow && currentCheckedRow.category.trim().length === 0) {
        isError = true
      }

      this.memberCostData.find((data) => data.id === row.id).isError = isError
    },
    onRowCheck (checkedList, row) {
      this.checkedMemberCostRows = checkedList
      return this.checkRowHasCategory(row)
    }
  }
}
</script>

<style scoped>
.step-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.column-header {
  vertical-align: middle !important;
  height: 60px;
}

.column-header span {
  font-size: 12px;
  font-weight: bold;
}

.left-align {
  text-align: left !important;
}

.cost-inputs-table {
  margin-bottom: 40px;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
    background-color: #F6F7FA !important;
}

.member-cost-table .equal-column td, .rx-table .equal-column td {
  width: calc(100%/2);
}

.member-cost-table td, .rx-table td, .rx-table th, .member-cost-table th{
  vertical-align: middle !important;
}

.b-checkbox.checkbox {
  margin: 0px !important;
}

.b-checkbox.checkbox .control-label {
  padding-left: 0px !important;
}

.fa-times-circle {
  color: #E93F46;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.table thead th {
  background-color: #EBEBEB !important;
}

.user-input-wrapper {
  display: flex;
  text-align: left;
  margin-bottom: 8px;
}

.user-input-wrapper .field {
  max-width: 300px;
  min-width: 200px;
  flex-basis: 30%;
  margin-right: 32px;
}
</style>
