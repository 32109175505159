<template>
  <div class="step-container">
    <div class="content-wrapper">

      <!-- Details section -->
      <b-field
        class="step-field"
        v-for="(detail, index) in detailsSection"
        :key="generateFieldKey(detail.name, detail.value, detail.type)"
        :label="detail.display"
      >
        <CustomSelect
          :placeholder="`Select a ${detail.display.toLowerCase()}`"
          :options="formSelectOptions[detail.name]"
          :default="detail.value"
          :type="formDisplay[detail.name].type"
          @input="handleSelect($event, 'detailsSection', index)"
        />
      </b-field>

      <!-- Accumulators section -->
      <div
        class="step-field accumulator-container"
      >
        <div class="step-title">
          <p>Accumulators</p>
        </div>

        <table>
          <tr>
            <th>
            </th>
            <th>
              In-Network
              <CustomSelect
                :placeholder="`Select a ${inNetworkSection[0].display.toLowerCase()} label`"
                :options="formSelectOptions[inNetworkSection[0].name]"
                :default="formSelectOptions[inNetworkSection[0].name].find(element => element?.toLowerCase().includes(inNetworkSection[0].value?.toLowerCase()))"
                :type="formDisplay[inNetworkSection[0].name].type"
                @input="handleSelect($event, 'inNetworkSection', 0)"
              />
            </th>
            <th>
              Out-of-Network
              <CustomSelect
                v-if="outOfNetworkSection[0].type === 'select'"
                :placeholder="`Select a ${outOfNetworkSection[0].display?.toLowerCase()} label`"
                :options="formSelectOptions[outOfNetworkSection[0].name]"
                :default="formSelectOptions[outOfNetworkSection[0].name].find(element => element?.toLowerCase().includes(outOfNetworkSection[0].value?.toLowerCase()))"
                :type="formDisplay[outOfNetworkSection[0].name].type"
                @input="handleSelect($event, 'outOfNetworkSection', 0)"
              />
            </th>
          </tr>

          <tr>
            <td>Individual Deductible</td>
            <td>
              <b-field
                class="step-field"
                :key="generateFieldKey(inNetworkSection[2].name, inNetworkSection[2].value, inNetworkSection[2].type)">
                <b-input
                    class="value-input"
                    size="is-medium"
                    :type="formDisplay.inNetworkDeductiblePatientAmount.type"
                    :value="formDisplay.inNetworkDeductiblePatientAmount.message"
                    @input.native="handleInput($event, 'inNetwork', 'inNetworkDeductiblePatientAmount')"
                    @focus="handleFocus($event, 'inNetwork', 'inNetworkDeductiblePatientAmount')"
                    @blur="handleFocusOut($event, 'inNetwork', 'inNetworkDeductiblePatientAmount')"
                />
                <div
                  class="toggle-container"
                  v-if="inNetworkSection[2].toggle !== undefined"
                >
                  <Toggle
                    :checked="inNetworkSection[2].toggle"
                    @change="handleToggle($event, 'inNetworkSection', 2)"
                    :disabled="!inNetworkSection[2].value"
                  />
                </div>
              </b-field>
            </td>
            <td>
              <b-field
                class="step-field"
                :key="generateFieldKey(outOfNetworkSection[2].name, outOfNetworkSection[2].value, outOfNetworkSection[2].type)">
                <b-input
                    class="value-input"
                    size="is-medium"
                    :type="formDisplay.outOfNetworkDeductiblePatientAmount.type"
                    :value="formDisplay.outOfNetworkDeductiblePatientAmount.message"
                    @input.native="handleInput($event, 'outOfNetwork', 'outOfNetworkDeductiblePatientAmount')"
                    @focus="handleFocus($event, 'outOfNetwork', 'outOfNetworkDeductiblePatientAmount')"
                    @blur="handleFocusOut($event, 'outOfNetwork', 'outOfNetworkDeductiblePatientAmount')"
                />
                <div
                  class="toggle-container"
                  v-if="outOfNetworkSection[2].toggle !== undefined"
                >
                  <Toggle
                    :checked="outOfNetworkSection[2].toggle"
                    @change="handleToggle($event, 'outOfNetworkSection', 2)"
                    :disabled="!outOfNetworkSection[2].value"
                  />
                </div>
              </b-field>
            </td>
          </tr>

          <tr>
            <td>Family Deductible</td>
            <td>
              <b-field
                class="step-field"
                :key="generateFieldKey(inNetworkSection[1].name, inNetworkSection[1].value, inNetworkSection[1].type)">
                <b-input
                    class="value-input"
                    size="is-medium"
                    :type="formDisplay.inNetworkDeductibleFamilyAmount.type"
                    :value="formDisplay.inNetworkDeductibleFamilyAmount.message"
                    @input.native="handleInput($event, 'inNetwork', 'inNetworkDeductibleFamilyAmount')"
                    @focus="handleFocus($event, 'inNetwork', 'inNetworkDeductibleFamilyAmount')"
                    @blur="handleFocusOut($event, 'inNetwork', 'inNetworkDeductibleFamilyAmount')"
                />
                <div
                  class="toggle-container"
                  v-if="inNetworkSection[1].toggle !== undefined"
                >
                  <Toggle
                    :checked="inNetworkSection[1].toggle"
                    @change="handleToggle($event, 'inNetworkSection', 1)"
                    :disabled="!inNetworkSection[1].value"
                  />
                </div>
              </b-field>
            </td>
            <td>
              <b-field
                class="step-field"
                :key="generateFieldKey(outOfNetworkSection[1].name, outOfNetworkSection[1].value, outOfNetworkSection[1].type)">
                <b-input
                    class="value-input"
                    size="is-medium"
                    :type="formDisplay.outOfNetworkDeductibleFamilyAmount.type"
                    :value="formDisplay.outOfNetworkDeductibleFamilyAmount.message"
                    @input.native="handleInput($event, 'outOfNetwork', 'outOfNetworkDeductibleFamilyAmount')"
                    @focus="handleFocus($event, 'outOfNetwork', 'outOfNetworkDeductibleFamilyAmount')"
                    @blur="handleFocusOut($event, 'outOfNetwork', 'outOfNetworkDeductibleFamilyAmount')"
                />
                <div
                  class="toggle-container"
                  v-if="outOfNetworkSection[1].toggle !== undefined"
                >
                  <Toggle
                    :checked="outOfNetworkSection[1].toggle"
                    @change="handleToggle($event, 'outOfNetworkSection', 1)"
                    :disabled="!outOfNetworkSection[1].value"
                  />
                </div>
              </b-field>
            </td>
          </tr>

          <tr>
            <td>Individual Out-of-Pocket Max</td>
            <td>
              <b-field
                class="step-field"
                :key="generateFieldKey(inNetworkSection[4].name, inNetworkSection[4].value, inNetworkSection[4].type)">
                <b-input
                  class="value-input"
                  size="is-medium"
                  :type="formDisplay.inNetworkOutOfPocketPatientMaxAmount.type"
                  :value="formDisplay.inNetworkOutOfPocketPatientMaxAmount.message"
                  @input.native="handleInput($event, 'inNetwork', 'inNetworkOutOfPocketPatientMaxAmount')"
                  @focus="handleFocus($event, 'inNetwork', 'inNetworkOutOfPocketPatientMaxAmount')"
                  @blur="handleFocusOut($event, 'inNetwork', 'inNetworkOutOfPocketPatientMaxAmount')"
                />
                <div
                  class="toggle-container"
                  v-if="inNetworkSection[4].toggle !== undefined"
                >
                  <Toggle
                    :checked="inNetworkSection[4].toggle"
                    @change="handleToggle($event, 'inNetworkSection', 4)"
                    :disabled="!inNetworkSection[4].value"
                  />
                </div>
              </b-field>
            </td>
            <td>
              <b-field
                class="step-field"
                :key="generateFieldKey(outOfNetworkSection[4].name, outOfNetworkSection[4].value, outOfNetworkSection[4].type)">
                <b-input
                  class="value-input"
                  size="is-medium"
                  :type="formDisplay.outOfNetworkOutOfPocketPatientMaxAmount.type"
                  :value="formDisplay.outOfNetworkOutOfPocketPatientMaxAmount.message"
                  @input.native="handleInput($event, 'outOfNetwork', 'outOfNetworkOutOfPocketPatientMaxAmount')"
                  @focus="handleFocus($event, 'outOfNetwork', 'outOfNetworkOutOfPocketPatientMaxAmount')"
                  @blur="handleFocusOut($event, 'outOfNetwork', 'outOfNetworkOutOfPocketPatientMaxAmount')"
                />
                <div
                  class="toggle-container"
                  v-if="outOfNetworkSection[4].toggle !== undefined"
                >
                  <Toggle
                    :checked="outOfNetworkSection[4].toggle"
                    @change="handleToggle($event, 'outOfNetworkSection', 4)"
                    :disabled="!outOfNetworkSection[4].value"
                  />
                </div>
              </b-field>
            </td>
          </tr>

          <tr>
            <td>Family Out-of-Pocket Max</td>
            <td>
              <b-field
                class="step-field"
                :key="generateFieldKey(inNetworkSection[3].name, inNetworkSection[3].value, inNetworkSection[3].type)">
                <b-input
                    class="value-input"
                    size="is-medium"
                    :type="formDisplay.inNetworkOutOfPocketFamilyMaxAmount.type"
                    :value="formDisplay.inNetworkOutOfPocketFamilyMaxAmount.message"
                    @input.native="handleInput($event, 'inNetwork', 'inNetworkOutOfPocketFamilyMaxAmount')"
                    @focus="handleFocus($event, 'inNetwork', 'inNetworkOutOfPocketFamilyMaxAmount')"
                    @blur="handleFocusOut($event, 'inNetwork', 'inNetworkOutOfPocketFamilyMaxAmount')"
                />
                <div
                  class="toggle-container"
                  v-if="inNetworkSection[3].toggle !== undefined"
                >
                  <Toggle
                    :checked="inNetworkSection[3].toggle"
                    @change="handleToggle($event, 'inNetworkSection', 3)"
                    :disabled="!inNetworkSection[3].value"
                  />
                </div>
              </b-field>
            </td>
            <td>
              <b-field
                class="step-field"
                :key="generateFieldKey(outOfNetworkSection[3].name, outOfNetworkSection[3].value, outOfNetworkSection[3].type)">
              <b-input
                    class="value-input"
                    size="is-medium"
                    :type="formDisplay.outOfNetworkOutOfPocketFamilyMaxAmount.type"
                    :value="formDisplay.outOfNetworkOutOfPocketFamilyMaxAmount.message"
                    @input.native="handleInput($event, 'outOfNetwork', 'outOfNetworkOutOfPocketFamilyMaxAmount')"
                    @focus="handleFocus($event, 'outOfNetwork', 'outOfNetworkOutOfPocketFamilyMaxAmount')"
                    @blur="handleFocusOut($event, 'outOfNetwork', 'outOfNetworkOutOfPocketFamilyMaxAmount')"
                />
                <div
                  class="toggle-container"
                  v-if="outOfNetworkSection[3].toggle !== undefined"
                >
                  <Toggle
                    :checked="outOfNetworkSection[3].toggle"
                    @change="handleToggle($event, 'outOfNetworkSection', 3)"
                    :disabled="!outOfNetworkSection[3].value"
                  />
                </div>
              </b-field>
            </td>
          </tr>
        </table>
      </div>

      <b-button
        outlined
        class="reset-accums"
        size='is-medium'
        type='is-secondary'
        @click="resetAccumulators"
      >
        Reset
      </b-button>

    </div>
  </div>
</template>

<script>
import store from '@/store/store'
import CustomSelect from '@/components/custom-select.vue'
import Toggle from '@/components/toggle/toggle.vue'

import { cloneDeep } from 'lodash'

export default {
  name: 'accumulators',
  props: [],
  components: {
    CustomSelect,
    Toggle
  },
  data () {
    const defaultData = store.getters['plans/accumulators/getDefaultData']
    const formData = store.getters['plans/accumulators/getAccumulatorsData']
    const selectOptions = store.getters['plans/accumulators/getSelectOptions']

    formData.inNetworkSection = formData.inNetworkSection.map(section => {
      return this.formatCurrencyFromSection(section)
    })

    formData.outOfNetworkSection = formData.outOfNetworkSection.map(section => {
      return this.formatCurrencyFromSection(section)
    })

    return {
      defaultData: defaultData,
      detailsSection: formData.detailsSection,
      inNetworkSection: formData.inNetworkSection,
      outOfNetworkSection: formData.outOfNetworkSection,
      formSelectOptions: selectOptions,
      formDisplay: {
        category: { type: '', message: '' },
        planYear: { type: '', message: '' },
        deductibleType: { type: '', message: '' },
        inNetworkLabel: { type: '', message: '' },
        outOfNetworkLabel: { type: '', message: '' },
        inNetworkDeductibleFamilyAmount: { type: 'text', message: formData.inNetworkSection[1].value },
        inNetworkDeductiblePatientAmount: { type: 'text', message: formData.inNetworkSection[2].value },
        outOfNetworkDeductibleFamilyAmount: { type: 'text', message: formData.outOfNetworkSection[1].value },
        outOfNetworkDeductiblePatientAmount: { type: 'text', message: formData.outOfNetworkSection[2].value },
        inNetworkOutOfPocketFamilyMaxAmount: { type: 'text', message: formData.inNetworkSection[3].value },
        inNetworkOutOfPocketPatientMaxAmount: { type: 'text', message: formData.inNetworkSection[4].value },
        outOfNetworkOutOfPocketFamilyMaxAmount: { type: 'text', message: formData.outOfNetworkSection[3].value },
        outOfNetworkOutOfPocketPatientMaxAmount: { type: 'text', message: formData.outOfNetworkSection[4].value }
      }
    }
  },
  methods: {
    formatCurrencyFromSection (section) {
      if (section.value && !isNaN(section.value)) {
        return (
          { ...section, value: section.value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,') }
        )
      } else {
        return (
          section
        )
      }
    },
    generateFieldKey (name, value, type) {
      return type && type === 'select'
        ? name + value
        : name
    },
    handleSelect (value, section, index) {
      this[section][index].value = value.toString()
    },
    handleInput (event, section, fieldName) {
      if (section === 'inNetwork' || section === 'outOfNetwork') {
        this.formDisplay[fieldName].message = event.target.value.replace(/[^0-9]/g, '')
        this.formDisplay[fieldName].type = 'number'
      }

      store.dispatch('plans/accumulators/setSectionFieldValue', {
        section: `${section}Section`,
        fieldName: fieldName,
        value: event.target.value
      })
    },
    handleFocus (event, section, fieldName) {
      if (section === 'inNetwork' || section === 'outOfNetwork') {
        this.formDisplay[fieldName].message = event.target.value.replace(/[^0-9]/g, '')
        this.formDisplay[fieldName].type = 'number'
      }
    },
    handleFocusOut (event, section, fieldName) {
      if (section === 'inNetwork' || section === 'outOfNetwork') {
        this.formDisplay[fieldName].type = 'text'
        this.formDisplay[fieldName].message = event.target.value.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      }
    },
    handleToggle (event, section, index) {
      this[section][index].toggle = event.target.checked
    },
    resetAccumulators () {
      Object.keys(this.defaultData).map((key) => {
        this[key] = cloneDeep(this.defaultData[key])
      })
    },
    saveSubmit () {
      const submitObject = {}
      this.inNetworkSection[0].value = this.inNetworkSection[0].value?.toUpperCase()
      this.outOfNetworkSection[0].value = this.outOfNetworkSection[0].value?.toUpperCase()
      const accumulatorsData = [...new Set([
        ...this.detailsSection,
        ...this.inNetworkSection,
        ...this.outOfNetworkSection
      ])]

      accumulatorsData.forEach(element => {
        submitObject[element.name] = (element.value && element.type === 'number')
          ? +element.value.toString().replace(/[^0-9]/g, '')
          : element.value

        if (element.toggle !== undefined) {
          submitObject[`${element.name}Disabled`] = !element.toggle
        }
      })

      store.dispatch('plans/accumulators/setAccumulatorsData', submitObject)

      // Solves issue of saving between tabs
      accumulatorsData.forEach(element => {
        if (element.value && element.type === 'number') {
          store.dispatch('plans/accumulators/setSectionFieldValue', {
            section: `${element.name.split('Network')[0]}NetworkSection`,
            fieldName: element.name,
            value: element.value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
          })
        }
      })

      return true
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.accumulator-container {
  margin-left: -10vw;
  width: 50vw;
}

.step-container {
  width: 35%;
}

.step-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-field {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
}

.step-field:not(:last-child):not(.no-margin) {
  margin-bottom: 40px;
}

.columns.step-field:not(:last-child):not(.no-margin) {
  margin-bottom: 24px;
}

.custom-select .disabled {
  opacity: .5;
}

.step-container {
  width: 35%;
}

.toggle-container {
  z-index: 3;
  margin-left: -60px;
  margin-top: 10px;
}

.value-input {
  vertical-align: middle;
}

.switch {
  margin-right: 0;
  margin-left: 16px;
}

@media screen and (max-width: 1200px) {
  .step-container {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .step-container {
    width: 80%;
  }
}

td {
  padding: 10px;
  border: 1px solid #D8D8D8;
  vertical-align: middle;
}

tr:nth-child(even) {
  background-color: #F6F7FA;
}

th {
  padding: 10px;
  background-color: #EBEBEB !important;
}

</style>
