<template>
  <div class="container step-container">
    <div class="content-wrapper">
      <b-field
        label="Plan Name"
        class="step-field"
        :type="formDisplay.planName.type"
        :message="formDisplay.planName.message"
      >
        <b-input
          size="is-medium"
          v-model.trim="formData.planName.value"
          @input="checkFieldHasValue('planName')"
        >
        </b-input>
      </b-field>
      <div class="date-wrapper" :key="formDisplay.endDate.message">
        <b-field
          label="Plan Start Date"
          class="step-field"
          :type="formDisplay.startDate.type"
          :message="formDisplay.startDate.message"
        >
          <b-datepicker
            size="is-medium"
            placeholder="MM/DD/YYYY"
            v-model="formData.startDate.value"
            :locale="locale"
            icon="calendar"
            icon-pack="fas"
            @blur="checkDateValidity()"
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Plan End Date"
          id="plan-end-date"
          class="step-field"
          :type="formDisplay.endDate.type"
          :message="formDisplay.endDate.message"
        >
          <b-datepicker
            size="is-medium"
            placeholder="MM/DD/YYYY"
            v-model="formData.endDate.value"
            :locale="locale"
            icon="calendar"
            icon-pack="fas"
            @blur="checkDateValidity()"
          >
          </b-datepicker>
        </b-field>
      </div>
      <b-field
        label="Plan Type"
        :class="
          displayFullDirectoryCheckbox ? 'step-field no-margin' : 'step-field'
        "
        :type="formDisplay.planType.type"
        :message="formDisplay.planType.message"
      >
        <CustomSelect
          placeholder="Select a plan type"
          :options="planTypes"
          :default="formData.planType.display"
          :type="formDisplay.planType.type"
          @input="selectPlanType"
        />
      </b-field>
      <b-field v-if="displayFullDirectoryCheckbox" class="step-field">
        <b-checkbox
          name="network-type"
          size="is-medium"
          v-model="formData.fullDirectory.value"
        >
          <span class="open-sans checkbox-text"
            >If Open Access, check this box if the plan requires the internal
            Centivo directory</span
          >
        </b-checkbox>
      </b-field>
      <b-field
        label="CMS ID"
        class="step-field"
        :type="formDisplay.cmsId.type"
        :message="formDisplay.cmsId.message"
      >
        <b-input
          size="is-medium"
          v-model.trim="formData.cmsId.value"
          @input="checkFieldHasValue('cmsId')"
        >
        </b-input>
      </b-field>
      <b-field
        label="CMS Name"
        class="step-field"
        :type="formDisplay.networkName.type"
        :message="formDisplay.networkName.message">
        <b-input
          size="is-medium"
          v-model.trim="formData.networkName.value"
          @input="checkFieldHasValue('networkName')"
        >
        </b-input>
      </b-field>
      <b-field
        label="Centivo contact us phone number"
        class="step-field"
        :type="formDisplay.contactNumber.type"
        :message="formDisplay.contactNumber.message"
      >
        <b-input
          size="is-medium"
          placeholder="XXX-XXX-XXXX"
          v-model="formData.contactNumber.display"
          v-mask="'###-###-####'"
          @blur="checkContactNumber()"
        >
        </b-input>
      </b-field>
      <b-field
        label="Member Care contact us hours"
        class="step-field"
        :type="formDisplay.supportHours.type"
        :message="formDisplay.supportHours.message"
      >
        <b-input
          size="is-medium"
          v-model.trim="formData.supportHours.value"
          @blur="checkFieldHasValue('supportHours')"
        >
        </b-input>
      </b-field>
      <b-field
        label="Sender Email"
        class="step-field"
        :type="formDisplay.senderEmail.type"
        :message="formDisplay.senderEmail.message"
      >
        <b-input
          size="is-medium"
          v-model.trim="formData.senderEmail.value"
          @blur="checkFieldHasValue('senderEmail')"
        >
        </b-input>
      </b-field>
      <b-field
        label="Portal URL"
        class="step-field"
        :type="formDisplay.portalUrl.type"
        :message="formDisplay.portalUrl.message"
      >
        <b-input
          size="is-medium"
          v-model.trim="formData.portalUrl.value"
          @blur="checkFieldHasValue('portalUrl')"
        >
        </b-input>
      </b-field>
      <b-field label="PCP designation" class="step-field no-margin">
        <b-radio
          size="is-medium"
          native-value="required"
          v-model="formData.pcpDesignation.value"
          @input="selectPCPDesignation()"
        >
          <span class="open-sans">Required / Optional</span>
        </b-radio>
      </b-field>
      <b-field
        class="step-field"
        :type="formDisplay.pcpDesignation.type"
        :message="formDisplay.pcpDesignation.message"
      >
        <b-radio
          size="is-medium"
          native-value="none"
          v-model="formData.pcpDesignation.value"
          @input="selectPCPDesignation()"
        >
          <span class="open-sans">None</span>
        </b-radio>
      </b-field>
      <b-field label="Virtual Care" class="step-field no-margin">
        <b-radio
          size="is-medium"
          :native-value="true"
          v-model="formData.vpcEnabled.value"
          @input="selectVPC()"
        >
          <span class="open-sans">Yes</span>
        </b-radio>
      </b-field>
      <b-field
        class="step-field"
        :type="formDisplay.vpcEnabled.type"
        :message="formDisplay.vpcEnabled.message"
      >
        <b-radio
          size="is-medium"
          :native-value="false"
          v-model="formData.vpcEnabled.value"
          @input="selectVPC()"
        >
          <span class="open-sans">No</span>
        </b-radio>
      </b-field>
      <b-field label="Referrals" class="step-field no-margin">
        <b-radio
          size="is-medium"
          native-value="yes"
          v-model="formData.referrals.value"
          @input="selectReferral()"
        >
          <span class="open-sans">Yes</span>
        </b-radio>
      </b-field>
      <b-field
        class="step-field"
        :type="formDisplay.referrals.type"
        :message="formDisplay.referrals.message"
      >
        <b-radio
          size="is-medium"
          native-value="no"
          v-model="formData.referrals.value"
          @input="selectReferral()"
        >
          <span class="open-sans">No</span>
        </b-radio>
      </b-field>
      <b-field
        label="Referral Type (if applicable)"
        class="step-field"
        :type="formDisplay.referralType.type"
        :message="formDisplay.referralType.message"
      >
        <CustomSelect
          placeholder="Select a referral type"
          :options="referralTypeOptions"
          :default="formData.referralType.display"
          :type="formDisplay.referralType.type"
          :disabled="formData.referrals.value !== 'yes'"
          @input="selectReferralType"
        />
      </b-field>
      <b-field
        label="MFA"
        class="step-field"
        :type="formDisplay.mfa.type"
        :message="formDisplay.mfa.message"
      >
        <CustomSelect
          placeholder="Select a MFA option"
          :options="mfaOptions"
          :default="formData.mfa.display"
          :type="formDisplay.mfa.type"
          @input="selectMFA"
        />
      </b-field>
      <b-field label="Password Requirements" class="step-field no-margin">
      </b-field>
      <b-field
        label="Password Helper Text"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.passwordHelperText.type"
        :message="formDisplay.passwordHelperText.message"
      >
        <b-input
          size="is-medium"
          type="textarea"
          v-model.trim="formData.passwordHelperText.value"
          @input="checkFieldHasValue('passwordHelperText')"
        >
        </b-input>
      </b-field>
      <b-field
        label="Minimum Number of Characters"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.minCharactersNo.type"
        :message="formDisplay.minCharactersNo.message"
      >
        <b-input
          type="number"
          min="8"
          size="is-medium"
          v-model="requirementsList.minCharactersNo"
          @input="setRegexExpression()"
        >
        </b-input>
      </b-field>
      <b-field
        label="Number of Special Characters"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.specialCharactersNo.type"
        :message="formDisplay.specialCharactersNo.message"
      >
        <b-input
          type="number"
          min="1"
          size="is-medium"
          v-model="requirementsList.specialCharactersNo"
          @input="setRegexExpression()"
        >
        </b-input>
      </b-field>
      <b-field
        label="Number of Letters"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.lettersNo.type"
        :message="formDisplay.lettersNo.message"
      >
        <b-input
          type="number"
          min="1"
          size="is-medium"
          v-model="requirementsList.lettersNo"
          @input="setRegexExpression()"
        >
        </b-input>
      </b-field>
      <b-field
        label="Number of Numbers"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.numbersNo.type"
        :message="formDisplay.numbersNo.message"
      >
        <b-input
          type="number"
          min="1"
          size="is-medium"
          v-model="requirementsList.numbersNo"
          @input="setRegexExpression()"
        >
        </b-input>
      </b-field>
      <br />
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import store from '@/store/store'
import CustomSelect from '@/components/custom-select.vue'

export default {
  name: 'plan-design',
  mounted () {
    this.customVisits =
      this.formData.referralsVisits.value === 'na'
        ? 1
        : this.formData.referralsVisits.value
    this.requirementsList.lettersNo = this.checkRequirementValue('[A-Z]')
    this.requirementsList.numbersNo = this.checkRequirementValue('*\\d')
    this.requirementsList.specialCharactersNo =
      this.checkRequirementValue('[@$!%*#?&]')
    this.requirementsList.minCharactersNo =
      this.checkRequirementValue('[A-Za-z\\d@$!%*#?&]')
  },
  props: [],
  data () {
    const formDataDefault = this.$store.getters[
      'plans/planDesign/getPlanDesignData'
    ] ?? {
      contactNumber: { display: '', value: undefined },
      supportHours: { display: '', value: undefined },
      portalUrl: { display: '', value: undefined },
      pcpDesignation: { display: '', value: undefined },
      vpcEnabled: { display: '', value: undefined },
      referrals: { display: '', value: undefined },
      referralsDays: { display: '', value: undefined },
      referralsVisits: { display: '', value: undefined },
      startDate: { display: '', value: undefined },
      endDate: { display: '', value: undefined },
      planName: { display: '', value: undefined },
      cmsId: { display: '', value: undefined },
      networkName: { display: '', value: undefined },
      planType: { display: '', value: undefined },
      mfa: { display: '', value: undefined },
      referralType: { display: '', value: undefined },
      passwordHelperText: { display: '', value: undefined },
      mfaPasswordRequirements: { display: '', value: undefined },
      fullDirectory: { display: '', value: false },
      senderEmail: { display: '', value: 'The Centivo Team <No_Reply@centivo.com>' }
    }

    // const networkDataDefault = store.getters['plans/network/getNetworkData'] ?? {
    //   networkName: { display: '', value: undefined }
    //   // networkName: undefined
    // }

    // formDataDefault.networkName = { display: networkDataDefault.networkName, value: networkDataDefault.networkName }

    return {
      labelPosition: 'inside',
      locale: 'en-US',
      formData: formDataDefault,
      formDisplay: {
        contactNumber: { type: '', message: '' },
        supportHours: { type: '', message: '' },
        portalUrl: { type: '', message: '' },
        pcpDesignation: { type: '', message: '' },
        vpcEnabled: { type: '', message: '' },
        referrals: { type: '', message: '' },
        referralsDays: { type: '', message: '' },
        referralsVisits: { type: '', message: '' },
        customVisits: { type: '', message: '' },
        startDate: { type: '', message: '' },
        endDate: { type: '', message: '' },
        planName: { type: '', message: '' },
        cmsId: { type: '', message: '' },
        networkName: { type: '', message: '' },
        planType: { type: '', message: '' },
        mfa: { type: '', message: '' },
        referralType: { type: '', message: '' },
        passwordHelperText: { type: '', message: '' },
        mfaPasswordRequirements: { type: '', message: '' },
        minCharactersNo: { type: '', message: '' },
        specialCharactersNo: { type: '', message: '' },
        lettersNo: { type: '', message: '' },
        numbersNo: { type: '', message: '' },
        fullDirectory: { type: '', message: '' },
        senderEmail: { type: '', message: '' }
      },
      formErrors: {
        contactNumber: 'Please enter a valid phone number',
        supportHours: 'Please enter Member Care contact hours',
        planType: 'Please select a plan type',
        mfa: 'Please select a MFA option',
        pcpDesignation: 'Please select a pcp designation option',
        vpcEnabled: 'Please select a virtual primary care option',
        referrals: 'Please select a referral option',
        referralType: 'Please select a referral type',
        referralsDays: 'Please select a referral days option',
        referralsDaysCustom: 'Please enter a valid number of days',
        referralsVisits: 'Please select a referral visits option',
        referralsVisitsCustom: 'Please enter a valid number of visits',
        invalidDate: 'Please enter a valid date.',
        planName: 'Please enter a plan name',
        portalUrl: 'Please enter a portal URL',
        cmsId: 'Please enter a cmsId',
        networkName: 'Please enter a CMS name',
        mfaPasswordRequirements: 'Please enter valid password requirements',
        passwordHelperText: 'Please enter a valid message',
        minCharactersNo:
          'Please enter a minimum number of characters higher than 7',
        specialCharactersNo: 'Please enter a valid number of special characters',
        lettersNo: 'Please enter a valid number of letters',
        numbersNo: 'Please enter a valid number of numbers',
        senderEmail: 'Please enter a sender email'
      },
      customVisits: 1,
      networkTypes: store.getters['plans/getNetworkTypes'],
      planTypes: ['OpenAccess', 'Gated'],
      mfaOptions: ['Forced', 'Default', 'Optional'],
      referralTypeOptions: store.getters['plans/getReferralTypes'],
      requirementsList: {
        lettersNo: '',
        numbersNo: '',
        specialCharactersNo: '',
        minCharactersNo: ''
      },
      displayFullDirectoryCheckbox:
        formDataDefault.planType.value === 'OpenAccess'
    }
  },
  methods: {
    setFieldInvalid (field) {
      this.formDisplay[field].type = 'is-danger'
      this.formDisplay[field].message = this.formErrors[field]
    },
    setFieldValid (field) {
      this.formDisplay[field].type = 'is-success'
      this.formDisplay[field].message = ''
    },
    setFieldDefault (field) {
      this.formDisplay[field].type = ''
      this.formDisplay[field].message = ''
    },
    setDefaultRequirement (key, value) {
      if (value !== 0) {
        switch (key) {
          case 'lettersNo':
            return value === 1 ? '(?=.*[a-z])(?=.*[A-Z])' : `(?=.*[a-z]{${value}})(?=.*[A-Z]{${value}})`
          case 'numbersNo':
            return value === 1 ? '(?=.*\\d)' : `(?=.*\\d{${value}})`
          case 'specialCharactersNo':
            return value === 1
              ? '(?=.*[@$!%*#?&])'
              : `(?=.*[@$!%*#?&]{${value}})`
          case 'minCharactersNo':
            return `[A-Za-z\\d@$!%*#?&]{${value},}`
        }
      } else {
        return ''
      }
    },
    setRegexExpression () {
      const passwordRequirements = []
      if (this.checkPasswordRequirementsValidity()) {
        for (const [key, value] of Object.entries(this.requirementsList)) {
          if (value && value !== '') {
            passwordRequirements.push(this.setDefaultRequirement(key, +value))
          }
        }

        this.formData.mfaPasswordRequirements.value = `^${passwordRequirements.join(
          ''
        )}$`
      }
    },
    checkFieldHasValue (field) {
      if (
        this.formData[field].value === undefined ||
        this.formData[field].value === null ||
        this.formData[field].value === ''
      ) {
        if (field !== 'passwordHelperText') {
          this.setFieldInvalid(field)
        } else {
          this.setFieldValid(field)
        }
      } else {
        this.setFieldValid(field)
      }
    },
    checkRequirementValue (value) {
      const requirementsValue = this.formData.mfaPasswordRequirements.value
      // this is very hacky but checking if the old buggy regex exists and if so, replacing it
      if (this.formData.mfaPasswordRequirements.value === '^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$' ||
          this.formData.mfaPasswordRequirements.value === undefined ||
          this.formData.mfaPasswordRequirements.value === null ||
          this.formData.mfaPasswordRequirements.value === '') {
        this.formData.mfaPasswordRequirements.value = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$'
      }

      if (requirementsValue) {
        const splitResult = requirementsValue.split(/[()]+/)
        const findResult = splitResult.find((res) => res.includes(value))
        const matchResult = findResult?.match(/\d+/g)

        return findResult && matchResult ? matchResult[0] : findResult ? 1 : '0'
      } else {
        return ''
      }
    },
    checkContactNumber () {
      var matchResult = String(this.formData.contactNumber.display).match(
        /^([0-9]{3})[-]([0-9]{3})[-]([0-9]{4})$/
      )

      if (matchResult === null) {
        this.formDisplay.contactNumber.type = 'is-danger'
        this.formDisplay.contactNumber.message = this.formErrors.contactNumber
        this.formData.contactNumber.value = undefined
      } else {
        this.formDisplay.contactNumber.type = 'is-success'
        this.formDisplay.contactNumber.message = ''
        this.formData.contactNumber.value = `${matchResult['1']}${matchResult['2']}${matchResult['3']}`
      }
    },
    checkDateValidity () {
      const startDate = this.formData.startDate.value
      const endDate = this.formData.endDate.value

      this.formDisplay.startDate.type = startDate ? 'is-success' : 'is-danger'
      this.formDisplay.startDate.message = startDate
        ? ''
        : this.formErrors.invalidDate

      this.formDisplay.endDate.type = endDate ? 'is-success' : 'is-danger'
      this.formDisplay.endDate.message = endDate
        ? ''
        : this.formErrors.invalidDate

      if (startDate >= endDate) {
        this.formDisplay.endDate.type = 'is-danger'
        this.formDisplay.endDate.message =
          'End date must be greater than start date'
      }

      return startDate < endDate && startDate && endDate
    },
    checkPasswordRequirementsValidity () {
      const requirements = this.requirementsList
      const minNumCharacters = +requirements.minCharactersNo
      const requirementsSum =
        +requirements.specialCharactersNo +
        +requirements.lettersNo +
        +requirements.numbersNo
      let hasEmptyFields = false

      for (const field in requirements) {
        if (this.requirementsList[field] && +this.requirementsList[field] > 0) {
          this.setFieldValid(field)
        } else {
          hasEmptyFields = true
          this.setFieldInvalid(field)
        }
      }

      if (minNumCharacters < 8) {
        this.formDisplay.minCharactersNo.type = 'is-danger'
        this.formDisplay.minCharactersNo.message =
          'The minimum number of characters must be at least 8'
      } else if (requirementsSum > minNumCharacters) {
        this.formDisplay.minCharactersNo.type = 'is-danger'
        this.formDisplay.minCharactersNo.message =
          'Minimum number of characters must be higher than the sum of the requirements'
      }

      return (
        minNumCharacters >= requirementsSum &&
        minNumCharacters >= 8 &&
        !hasEmptyFields
      )
    },
    checkFieldValidity (fieldIdentifier) {
      this.formDisplay[fieldIdentifier].type = this.formData[fieldIdentifier]
        .value
        ? 'is-success'
        : 'is-danger'
      this.formDisplay[fieldIdentifier].message = this.formData[fieldIdentifier]
        .value
        ? this.formErrors.invalidDate
        : ''
    },
    checkOptionalReferralData (field) {
      if (field === 'referralType' || field === 'referralsDays') {
        return this.formData.referrals.value === 'yes'
      } else {
        return field
      }
    },
    selectPCPDesignation () {
      this.formDisplay.pcpDesignation.type = 'is-success'
      this.formDisplay.pcpDesignation.message = ''
    },
    selectVPC () {
      this.formDisplay.vpcEnabled.type = 'is-success'
      this.formDisplay.vpcEnabled.message = ''
    },
    selectReferral () {
      if (this.formData.referrals.value === 'no') {
        this.clearReferralData()
      } else {
        this.formData.referralsDays.value = '365'
      }

      this.formDisplay.referrals.type = 'is-success'
      this.formDisplay.referrals.message = ''
    },
    selectReferralType (value) {
      this.formData.referralType.value = value
      if (!this.formData.referralType.value) {
        this.formDisplay.referralType.type = 'is-danger'
        this.formDisplay.referralType.message = this.formErrors.referralType
      } else {
        this.formDisplay.referralType.type = 'is-success'
        this.formDisplay.referralType.message = ''
      }
    },
    selectPlanType (value) {
      this.formData.planType.value = value
      if (!this.formData.planType.value) {
        this.formDisplay.planType.type = 'is-danger'
        this.formDisplay.planType.message = this.formErrors.planType
      } else {
        this.handleFullDirectory(value)
        this.formDisplay.planType.type = 'is-success'
        this.formDisplay.planType.message = ''
      }
    },
    handleFullDirectory (value) {
      this.displayFullDirectoryCheckbox = value === 'OpenAccess'
      if (!this.displayFullDirectoryCheckbox) {
        this.formData.fullDirectory.value = false
      }
    },
    selectMFA (value) {
      this.formData.mfa.value = value
      if (!this.formData.mfa.value) {
        this.formDisplay.mfa.type = 'is-danger'
        this.formDisplay.mfa.message = this.formErrors.mfa
      } else {
        this.formDisplay.mfa.type = 'is-success'
        this.formDisplay.mfa.message = ''
      }
    },
    clearReferralData () {
      ['referralType', 'referralsDays'].forEach((field) => {
        if (this.formData[field]) {
          this.formData[field].value = undefined
          this.formData[field].display = ''
        } else {
          this[field] = undefined
        }
        this.formDisplay[field].type = ''
        this.formDisplay[field].message = ''
      })
    },
    // setNetworkName (value) {
    //   this.formData.networkName = value
    //   // this.setFieldValid('networkType')

    //   if (!value) {
    //     this.formDisplay.networkName.type = ''
    //     this.formDisplay.networkName.message = ''
    //   } else {
    //     this.setFieldValid('networkName')
    //   }
    // },
    saveSubmit () {
      let validationFailed = false

      Object.keys(this.formData).forEach((field) => {
        if (this.checkOptionalReferralData(field)) {
          if (
            this.formData[field].value === undefined ||
            this.formData[field].value === null ||
            this.formData[field].value === ''
          ) {
            if (field !== 'passwordHelperText') {
              this.formDisplay[field].type = 'is-danger'
              this.formDisplay[field].message = this.formErrors[field]
              validationFailed = true
            } else {
              this.formDisplay[field].type = 'is-success'
              this.formDisplay[field].message = ''
            }
          } else {
            this.formDisplay[field].type = 'is-success'
            this.formDisplay[field].message = ''
          }
        }
      })

      validationFailed =
        validationFailed ||
        !this.checkDateValidity() ||
        !this.checkPasswordRequirementsValidity()

      if (validationFailed) {
        return false
      } else {
        this.$store.dispatch('plans/planDesign/setPlanDesignData', this.formData)
        return true
      }
    }
  },
  components: {
    CustomSelect
  },
  directives: {
    mask
  }
}
</script>

<style scoped>
.custom-radio-text {
  margin-top: 10px;
}
.step-title {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.step-field {
  text-align: left;
  font-family: "Open Sans", sans-serif;
}
.step-field:not(:last-child):not(.no-margin) {
  margin-bottom: 40px;
}
.columns.step-field:not(:last-child):not(.no-margin) {
  margin-bottom: 24px;
}
.step-container {
  width: 35%;
}
.date-wrapper .field:last-child {
  margin-bottom: 40px;
}
.checkbox-text {
  font-size: 16px;
}
.custom-select .disabled {
  opacity: .5;
}
@media screen and (max-width: 1200px) {
  .step-container {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .step-container {
    width: 80%;
  }
}
@media screen and (min-width: 830px) {
  .date-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .date-wrapper .field {
    flex-basis: 48%;
  }
}
</style>
