<template>
  <div class="container step-container">
    <div class="content-wrapper">
      <!-- <label class="label text-left">Telemedicine</label> -->
      <b-field label="Telemedicine" class="step-field no-margin">
        <b-radio
          name="telemedicine"
          size="is-medium"
          :native-value="undefined"
          v-model="formData.telemedicine"
          @input="unsetTelemedicineCustom()">
          <span class="open-sans has-text-grey">None</span>
        </b-radio>
      </b-field>
      <b-field v-for="option in telemedicineOptions" :key="option"
        class="step-field no-margin wrap-network-field">
        <b-radio
          name="telemedicine"
          size="is-medium"
          :native-value="option"
          v-model="formData.telemedicine"
          @input="unsetTelemedicineCustom()">
          <span class="open-sans">{{ option }}</span>
        </b-radio>
      </b-field>
      <b-field
        v-if="isTelemedicineMeMD()"
        label="Telemedicine Plan Code"
        class="step-field input-margin-top"
        :type="formDisplay.telemedicinePlanCode.type"
        :message="formDisplay.telemedicinePlanCode.message">
        <b-input
          size="is-medium"
          v-model.trim="formData.telemedicinePlanCode"
        >
        </b-input>
      </b-field>
      <b-field
        label="PBM"
        class="step-field custom-select"
        :type="formDisplay.pbmName.type"
        :message="formDisplay.pbmName.message">
        <CustomSelect
          placeholder="Select PBM Name"
          :options="pbmOptions"
          :type="formDisplay.pbmName.type"
          :default="formData.pbmName"
          @input="selectPBMName"/>
      </b-field>
      <b-field
        label="ID Card Info"
        class="step-field no-margin">
      </b-field>
      <b-field
        label="Group ID"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.groupId.type"
        :message="formDisplay.groupId.message">
        <b-input
          size="is-medium"
          v-model.trim="formData.groupId"
          @input="checkFieldHasValue('groupId')">
        </b-input>
      </b-field>
      <b-field
        label="Payer ID"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.payerId.type"
        :message="formDisplay.payerId.message">
        <b-input
          size="is-medium"
          v-model.trim="formData.payerId"
          @input="checkFieldHasValue('payerId')">
        </b-input>
      </b-field>
      <b-field
        label="Rx PCN"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.rxPCN.type"
        :message="formDisplay.rxPCN.message">
        <b-input
          size="is-medium"
          v-model.trim="formData.rxPCN"
          @input="checkFieldHasValue('rxPCN')">
        </b-input>
      </b-field>
      <b-field
        label="Rx BIN"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.rxBIN.type"
        :message="formDisplay.rxBIN.message">
        <b-input
          size="is-medium"
          v-model.trim="formData.rxBIN"
          @input="checkFieldHasValue('rxBIN')">
        </b-input>
      </b-field>
      <b-field
        label="Rx GRP"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.rxGRP.type"
        :message="formDisplay.rxGRP.message">
        <b-input
          size="is-medium"
          v-model.trim="formData.rxGRP"
          @input="checkFieldHasValue('rxGRP')">
        </b-input>
      </b-field>
      <b-field
        label="Rx Benefits Phone Number"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.rxBenefitsPhone.type"
        :message="formDisplay.rxBenefitsPhone.message">
        <b-input
          size="is-medium"
          v-model="formData.rxBenefitsPhone"
          v-mask="'###-###-####'"
          @input="checkPhoneNumber()">
        </b-input>
      </b-field>
      <b-field
        label="PBM Site URL (if applicable)"
        :label-position="labelPosition"
        class="step-field">
        <b-input
          @blur="checkURLInput('pbmSiteURL')"
          size="is-medium"
          v-model.trim="formData.pbmSiteURL">
        </b-input>
      </b-field>
      <b-field
        label="Formulary URL (if applicable)"
        :label-position="labelPosition"
        class="step-field">
        <b-input
          @blur="checkURLInput('formularyUrl')"
          size="is-medium"
          v-model.trim="formData.formularyUrl">
        </b-input>
      </b-field>
      <b-field
        label="Id Card Statement"
        :label-position="labelPosition"
        class="step-field"
        :type="formDisplay.idCardStatement.type"
        :message="formDisplay.idCardStatement.message">
        <b-input
          size="is-medium"
          v-model.trim="formData.idCardStatement"
          maxlength="150"
          type="textarea"
          @input="checkFieldHasValue('idCardStatement')">
        </b-input>
      </b-field>
      <br>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import CustomSelect from './custom-select.vue'

export default {
  name: 'coverage-details',
  data () {
    const formDataDefault = this.$store.getters['plans/coverageDetails/getCoverageDetailsData'] ?? {
      telemedicine: undefined,
      telemedicineCustom: undefined,
      telemedicinePlanCode: undefined,
      pbmName: undefined,
      groupId: undefined,
      payerId: undefined,
      rxPCN: undefined,
      rxBIN: undefined,
      rxGRP: undefined,
      rxBenefitsPhone: undefined,
      pbmSiteURL: undefined,
      formularyUrl: undefined,
      idCardStatement: undefined
    }

    // const networkDataDefault = store.getters['plans/network/getNetworkData'] ?? {
    //   telemedicine: undefined,
    //   telemedicineCustom: undefined,
    //   telemedicinePlanCode: undefined
    // }

    // formDataDefault.telemedicine = networkDataDefault.telemedicine
    // formDataDefault.telemedicineCustom = networkDataDefault.telemedicineCustom
    // formDataDefault.telemedicinePlanCode = networkDataDefault.telemedicinePlanCode

    // console.log('formDataDefault: ', formDataDefault)

    return {
      labelPosition: 'inside',
      telemedicineOptions: this.$store.getters['plans/getTelemedicineOptions'],
      pbmOptions: this.$store.getters['plans/getPbmNames'],
      formData: formDataDefault,
      formDisplay: {
        telemedicine: { type: '', message: '' },
        telemedicineCustom: { type: '', message: '' },
        telemedicinePlanCode: { type: '', message: '' },
        pbmName: { type: '', message: '' },
        groupId: { type: '', message: '' },
        payerId: { type: '', message: '' },
        rxPCN: { type: '', message: '' },
        rxBIN: { type: '', message: '' },
        rxGRP: { type: '', message: '' },
        rxBenefitsPhone: { type: '', message: '' },
        idCardStatement: { type: '', message: '' }
      },
      formErrors: {
        telemedicineCustom: 'Please enter a telemedicine provider',
        pbmName: 'Please select a PBM',
        groupId: 'Please enter a group ID',
        payerId: 'Please enter a payer ID',
        rxPCN: 'Please enter an Rx PCN',
        rxBIN: 'Please enter an Rx BIN',
        rxGRP: 'Please enter an Rx GRP',
        rxBenefitsPhone: 'Please enter a valid Rx benefits phone number',
        idCardStatement: 'Please enter an Id Card Statement'
      }
    }
  },
  methods: {
    setFieldInvalid (field) {
      this.formDisplay[field].type = 'is-danger'
      this.formDisplay[field].message = this.formErrors[field]
    },
    setFieldValid (field) {
      this.formDisplay[field].type = 'is-success'
      this.formDisplay[field].message = ''
    },
    setFieldDefault (field) {
      this.formDisplay[field].type = ''
      this.formDisplay[field].message = ''
    },
    isFieldOptional (field) {
      if (Object.keys(this.formErrors).includes(field) && field !== 'telemedicineCustom') {
        return true
      }
      return false
    },
    checkFieldHasValue (field) {
      if (this.formData[field] === undefined || this.formData[field] === null || this.formData[field] === '') {
        if (this.isFieldOptional(field)) {
          this.setFieldValid(field)
          return
        }
        this.setFieldInvalid(field)
      } else {
        this.setFieldValid(field)
      }
    },
    checkPhoneNumber () {
      if (!this.formData.rxBenefitsPhone) {
        this.setFieldValid('rxBenefitsPhone')
        return true
      }
      if (this.getTruePhoneNumber() === null) {
        this.setFieldInvalid('rxBenefitsPhone')
        return false
      }
      this.setFieldValid('rxBenefitsPhone')
      return true
    },
    checkURLInput (url) {
      const currentURL = this.formData[url]

      if (currentURL && !(currentURL.startsWith('https://'))) {
        this.formData[url] = 'https://' + currentURL
      }
    },
    unsetTelemedicineCustom () {
      this.setFieldValid('telemedicine')
      this.formDisplay.telemedicineCustom.type = ''
      this.formDisplay.telemedicineCustom.message = ''
      this.isTelemedicineMeMD()
    },
    isTelemedicineMeMD () {
      return (this.formData.telemedicine === 'Me MD')
    },
    selectPBMName (value) {
      this.formData.pbmName = value
      if (value === 'Other') {
        this.formData.pbmName = null
      }
      this.setFieldValid('pbmName')
    },
    getTruePhoneNumber () {
      var matchResult = ((String)(this.formData.rxBenefitsPhone)).match(/^([0-9]{3})[-]([0-9]{3})[-]([0-9]{4})$/)

      return matchResult ? `${matchResult['1']}${matchResult['2']}${matchResult['3']}` : null
    },
    saveSubmit () {
      let validationFailed = !this.checkPhoneNumber()

      Object.keys(this.formData).filter((key) => {
        return key.includes('telemedicine', 'telemedicineCustom', 'telemedicinePlanCode')
      }).forEach(field => {
        if (!this.formData[field] && (field !== 'telemedicineCustom' || this.formData.telemedicine === 'Other')) {
          if (!this.formErrors[field]) { // field does not have validation, not required
            return
          }
          this.formDisplay[field].type = 'is-danger'
          this.formDisplay[field].message = this.formErrors[field] || '-'
        } else if (field !== 'telemedicineCustom' || this.formData.telemedicine === 'Other') {
          this.setFieldValid(field)
        } else {
          this.formDisplay[field].type = ''
          this.formDisplay[field].message = ''
        }
      })

      Object.keys(this.formData).filter((key) => {
        return !key.includes('telemedicine', 'telemedicineCustom', 'telemedicinePlanCode')
      }).forEach(field => {
        switch (field) {
          case 'pbmSiteURL':
          case 'formularyUrl':
            this.checkURLInput(field)
            break
          case 'rxBenefitsPhone':
            break
          default:
            if (this.formData[field] === undefined || this.formData[field] === null || this.formData[field] === '') {
              if (this.isFieldOptional(field)) {
                this.setFieldValid(field)
                return
              }
              this.setFieldInvalid(field)
              validationFailed = true
            } else {
              this.setFieldValid(field)
            }
        }
      })

      if (validationFailed) {
        return false
      } else {
        this.$store.dispatch('plans/coverageDetails/setCoverageDetailsData', this.formData)
        return true
      }
    }
  },
  components: {
    CustomSelect
  },
  directives: {
    mask
  }
}
</script>

<style scoped>
.text-left {
  text-align: left;
}

.wrap-network-field .field-body .field {
  flex-direction: column;
}
.wrap-network-field .field-body .field label {
  margin-bottom: .75rem;
}
.step-container {
  width: 35%;
}
.step-field {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
}
/* .step-field:not(:last-child):not(.no-margin) {
  margin-bottom: 40px;
} */
/* .columns.step-field:not(:last-child):not(.no-margin) {
  margin-bottom: 24px;
} */

.step-field:not(:first-child):not(.no-margin) {
  margin-bottom: 40px;
  margin-top: 30px;
}
.columns.step-field:not(:first-child):not(.no-margin) {
  margin-bottom: 24px;
  margin-top: 24px;
}

.input-margin-top {
    margin-top: 40px;
    margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
  .step-container {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .step-container {
    width: 80%;
  }
}

.radio-area {
  display: flex;
  flex-direction: column;
}
</style>
