import store from '@/store/store'

var axios = require('axios')

const getFileTypes = () => {
  return ['idCard', 'spd', 'sbc']
}

const getPostUrl = async (fileName, fileType, docType) => {
  if (await store.dispatch('auth/isAuthenticated') === false) return

  const fnParam = `fileName=${encodeURIComponent(fileName)}`
  const ftParam = `fileType=application/${fileType}`
  const pdtParam = `planDocType=${docType}`

  return axios.get(`${process.env.VUE_APP_S3_UPLOAD_URL}/files/getSignedUrl?${fnParam}&${ftParam}&${pdtParam}`, {
    headers: {
      Authorization: `Bearer ${store.getters['auth/getAccessToken']}`
    }
  })
}

const postFile = async (url, file) => {
  if (await store.dispatch('auth/isAuthenticated') === false) return

  return axios.put(url, file, {
    headers: { 'Content-Type': file.type, 'x-amz-acl': 'public-read-write' }
    // headers: { 'Content-Type': file.type }
  })
}

export default {
  getFileTypes,
  getPostUrl,
  postFile
}
